
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const head = ref<any>([]);
    const permissions = ref<any>();
    const features = ref<any>();

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      features.value = localStorage.getItem("features");
      features.value = JSON.parse(features.value);
      const visibleMenuItems = MainMenuConfig.map(menuItem => {
        const filteredDevices = menuItem.devices.filter(device => permissions.value.includes(device.permission));
        if (filteredDevices.length > 0) {
            return {
                ...menuItem,
                devices: filteredDevices,
            };
        }
        return null;
    }).filter(Boolean);
      console.log("Main menu", MainMenuConfig);
      console.log("Visible menu", visibleMenuItems);
      head.value = visibleMenuItems;
      console.log("All menus", head.value);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      head,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
